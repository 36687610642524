import React, { useContext, useEffect, useRef } from "react";
import ServiceCard from "../components/Services/ServiceCard";
import Contact from "./Contact";
import certificate from "../images/certificate.png";
import lor from "../images/lor.png";
import { ProgramContext } from "../context/ProgramContext";
import { Link } from "react-router-dom";

const Programs = () => {
  const sectionRef = useRef(null);

  useEffect(() => {
    // Scroll to the sectionw hen the component mounts
    if (sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  const { programData } = useContext(ProgramContext);
  const externalUrl = "https://lms.kirabiz.in/membership-account/membership-levels/";
  const ServiceCardData = [
    {
      title: "Web Development",
      description:
        "Elevate your online presence with our cutting-edge web development services. From responsive designs to seamless user experiences, we create websites that captivate audiences and drive business growth. Our expert developers blend creativity and functionality to deliver websites that leave a lasting impression.",
    },
    {
      title: "App Development",
      description:
        "Transform your ideas into dynamic mobile experiences with our app development prowess. We specialize in crafting user-friendly and feature-rich applications for iOS and Android platforms. Whether it's for business or consumer needs, our apps are built to engage, inspire, and deliver exceptional value.",
    },
    {
      title: "Software Product",
      description:
        "Unlock the potential of your software product with our comprehensive services. From concept to deployment and beyond, we offer end-to-end solutions tailored to your product's unique requirements. With a focus on innovation, quality, and scalability, we ensure your software stands out in today's competitive market.",
    },
    {
      title: "Digital Marketing",
      description:
        "Navigate the digital landscape with our results-driven digital marketing strategies. From SEO and social media to content creation and paid campaigns, we orchestrate comprehensive approaches that boost your brand's visibility, engagement, and conversion rates. Let us optimize your digital footprint for success.",
    },
  ];
  return (
    <section ref={sectionRef}>
      <div className="bg-[#345fe0dd] h-16 "></div>
      {/* <section className=" relative w-[100%] h-[70vh] flex items-center justify-center overflow-hidden">
        <div className=" h-[100%] w-[100%] top-0 left-0 overflow-hidden absolute -z-10">
          <img src={bg} className="w-full h-full" />
        </div>
        <div className=" h-[100%] w-[100%] top-0 left-0 overflow-hidden absolute -z-10 bg-black/10 backdrop-blur-sm"></div>
        <div className=" flex flex-col items-center justify-center gap-20 z-10 px-4 text-white my-auto ">
          <h1 className="text-4xl sm:text-5xl md:text-6xl text-center font-semibold">
             Corporate Training
          </h1>
          <Link
            to={"/contact"}
            className="hover:bg-white hover:text-[#262e45] sm:text-lg font-[600] py-4 px-8 rounded-full text-white bg-[#345fe0] transition-colors duration-400 z-40 uppercase"
          >
            Request a CallBack
          </Link>
        </div>
      </section> */}
      <section className="pb-10 px-4 md:px-16 bg-[#F5F5F5]">
        <h1 className="text-4xl text-center font-semibold py-16">
          Corporate Training
        </h1>
        <div className=" rounded-2xl grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-5 gap-y-10 relative">
          {programData.map((card, ind) => (
            <a href={externalUrl} target="_blank" rel="noopener noreferrer">
              <ServiceCard
                title={card.name}
                description={card.description}
                type={"p_card"}
              />
            </a>
              
            
          ))}
        </div>
      </section>
      <section className="bg-[#345fe0] text-center px-5 py-16 text-[#ffffffdd]">
        <h3 className="text-center text-4xl font-bold w-full">
          Industry Recognized Certifications
        </h3>
        <div className=" flex justify-center gap-10  font-semibold text-xl py-10 max-sm:flex-col max-sm:items-center">
          <img
            src={certificate}
            className="w-[35%] max-xs:w-full max-sm:w-[70%]"
          />
          <img
            src={lor}
            className="w-[35%] max-xs:w-full max-sm:w-[70%]"
          />
        </div>
      </section>
      <section>
      {/* <Contact visible={false} /> */}
      </section>
    </section>
  );
};

export default Programs;
