import React, { useEffect, useRef, useState } from "react";
import contactImg from "../images/contactImg.png";

const Contact = ({ visible }) => {
  const sectionRef = useRef(null);
  useEffect(()=>{
    if(sectionRef.current){
      sectionRef.current.scrollIntoView({behaviour:"smooth"})
    }
  })

  const [value, setValue] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const handleOnChange = (e) => {
    setValue({ ...value, [e.target.name]: e.target.value });
  };
  return (
    <section ref={sectionRef}>
      <div
        className={`bg-[#345fe0dd] h-16 ${visible === false ? "hidden" : ""}`}
      ></div>
      <div className="w-[100%] min-h-[90vh] bg-[#F5F5F5] flex flex-col md:flex-row justify-around items-center py-5 ">
        <form className="bg-white rounded-xl w-[90%] sm:w-[70%] md:w-[30%] flex flex-col justify-center gap-5 p-7 h-[90%] shadow-xl">
          <h2 className="text-3xl font-semibold">Contact Us</h2>
          <input
            name="name"
            onChange={handleOnChange}
            type="text"
            placeholder="Name"
            className="bg-gray-200 p-2 rounded-lg focus:outline-none"
          />
          <input
            name="phone"
            onChange={handleOnChange}
            type="text"
            placeholder="Mobile No."
            className="bg-gray-200 p-2 rounded-lg focus:outline-none"
          />
          <input
            name="email"
            onChange={handleOnChange}
            type="email"
            placeholder="Email address"
            className="bg-gray-200 p-2 rounded-lg focus:outline-none"
          />
          <textarea
            name="message"
            onChange={handleOnChange}
            rows={5}
            placeholder="Message"
            className="bg-gray-200 px-2 py-3 rounded-lg focus:outline-none"
          />
          <a
            href={`mailto:kirabiz.2626@gmail.com?subject=${
              "Message from " + value.name
            }&body=${
              encodeURIComponent(value.message) +
              "%0AName: " +
              value.name +
              "%0AContact no: " +
              value.phone +
              "%0AEmail: " +
              value.email
            }`}
            className="hover:bg-white hover:text-[#262e45] text-lg font-[600] py-2 mx-auto px-8 rounded-xl text-white bg-[#345fe0] transition-colors duration-400 cursor-pointer"
          >
            Send Message
          </a>
        </form>
        <div className="w-[90%] sm:w-[70%] md:w-[50%]">
          <img src={contactImg} className="w-[90%] mx-auto animate-updown" />
        </div>
      </div>
    </section>
  );
};

export default Contact;
