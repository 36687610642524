import React, { useEffect, useRef, useState } from "react";
import about1 from "../images/aboutimage1.png";
import about3 from "../images/aboutimage2.png";
import about2 from "../images/aboutimage3.png";
import CountUp from "react-countup";
import { useLayoutEffect } from "react";
import { Link } from "react-router-dom";

const CancelationRefund = () => {
  const sectionRef = useRef(null);

  useEffect(() => {
    // Scroll to the sectionw hen the component mounts
    if (sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  return (
    <section ref={sectionRef}>
    <div className="bg-[#345fe0dd] h-16"></div>
      <div className="relative text-[#262e45bb]">
        <div className="flex items-center p-4 sm:p-10 w-[100%] flex-col-reverse sm:flex-row justify-around sm:sticky top-0 min-h-[300vh]  ">
          <div className="  sm:w-[40%] flex flex-col  justify-center gap-5">
            <h1 className=" text-3xl sm:text-4xl font-semibold text-center sm:text-left">
              Cancellations and Refund Policy
            </h1>
            <p className="text-justify sm:text-left">
              Welcome to Kirabiz, your trusted partner in navigating the digital
              landscape. We are a dynamic IT consultancy company dedicated to
              delivering innovative solutions tailored to your business needs.
              Our team of experts specializes in technology strategies, software
              development, cybersecurity, and more. With a commitment to
              excellence, we empower businesses to thrive in a rapidly evolving
              tech-driven world. Join us on a journey of growth, efficiency, and
              transformation.
            </p>
            <br />
            <h3 className=" text-3xl sm:text-4xl font-semibold text-center sm:text-left">
              Cancellation Policy
            </h3>
            <br />
            <h4 className=" text-3xl sm:text-4xl font-semibold text-center sm:text-left">
              Services for Clients
            </h4>

            <h2 className=" text-3xl sm:text-4xl font-semibold text-center sm:text-left">
              Website Development, Mobile App Development, and Digital Marketing
              Services:
            </h2>
            <p className="text-justify sm:text-left">
              Clients may request the cancellation of services by contacting our
              support team via email or phone. Cancellation requests received
              within 7 days of the service initiation date are eligible for a
              full refund. Cancellation requests received after the specified
              period will be subject to a prorated refund, minus any costs
              incurred by Kirabiz Technologies up to the cancellation date. We
              reserve the right to withhold a refund if substantial work or
              expenses have already been incurred for the project.
            </p>

            <h4 className=" text-3xl sm:text-4xl font-semibold text-center sm:text-left">
              Corporate Training
            </h4>

            <h2 className=" text-3xl sm:text-4xl font-semibold text-center sm:text-left">
              Cancellation by Students:
            </h2>
            <p className="text-justify sm:text-left">
              Students enrolled in our corporate training programs may request
              cancellations by contacting our training coordinator via email.
              Cancellation requests received within 7 days of the training
              program's start date are eligible for a full refund. Cancellation
              requests received after the specified period will not be eligible
              for a refund.
            </p>
            <br />
            <h3 className=" text-3xl sm:text-4xl font-semibold text-center sm:text-left">
              Refund Policy
            </h3>
            <br />
            <h4 className=" text-3xl sm:text-4xl font-semibold text-center sm:text-left">
              Services for Clients
            </h4>

            <h2 className=" text-3xl sm:text-4xl font-semibold text-center sm:text-left">
              Website Development, Mobile App Development, and Digital Marketing
              Services:
            </h2>
            <p className="text-justify sm:text-left">
              Refunds for eligible cancellations will be processed within 2 days
              of the cancellation request. Payments made via credit card will be
              refunded to the original credit card used for the transaction.
              Payments made via other methods, such as bank transfers or checks,
              will be refunded through the same method.
            </p>

            <h4 className=" text-3xl sm:text-4xl font-semibold text-center sm:text-left">
              Corporate Training
            </h4>

            <h2 className=" text-3xl sm:text-4xl font-semibold text-center sm:text-left">
              Refund for Cancelled Training Programs:
            </h2>
            <p className="text-justify sm:text-left">
              Refunds for eligible cancellations will be processed within 2 days
              of the cancellation request. Payments made via credit card will be
              refunded to the original credit card used for the transaction.
              Payments made via other methods, such as bank transfers or checks,
              will be refunded through the same method.
            </p>
            <br />
            <h2 className=" text-3xl sm:text-4xl font-semibold text-center sm:text-left">
              Contact Us
            </h2>
            <p className="text-justify sm:text-left">
              If you have any questions or concerns about our Cancellations and
              Refund Policy, please feel free to contact us:
              <br />
              Email: info@kirabiz.in
            </p>

            <h2 className=" text-3xl sm:text-4xl font-semibold text-center sm:text-left">
              Changes to this Policy
            </h2>
            <p className="text-justify sm:text-left">
              Kirabiz Technologies reserves the right to update or modify this
              Cancellations and Refund Policy at any time. Any changes will be
              posted on this page with an updated effective date. We thank you
              for choosing Kirabiz Technologies as your IT consultancy partner
              and for entrusting us with your projects and training needs. Your
              satisfaction is our priority, and we are dedicated to delivering
              exceptional service.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CancelationRefund;
