import React, { useEffect, useRef } from "react";
import AboutComp from "../components/AboutComp";

const About = () => {
  const sectionRef = useRef(null);

  useEffect(() => {
    // Scroll to the sectionw hen the component mounts
    if (sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);
  return (
    <section ref={sectionRef}>
      <div className="bg-[#345fe0dd] h-16"></div>
      <AboutComp />
    </section>
  );
};

export default About;
