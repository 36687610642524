import React, { useEffect, useRef, useState } from "react";
import about1 from "../images/aboutimage1.png";
import about3 from "../images/aboutimage2.png";
import about2 from "../images/aboutimage3.png";
import CountUp from "react-countup";
import { useLayoutEffect } from "react";
import { Link } from "react-router-dom";

const TermsOfService = () => {
  const sectionRef = useRef(null);

  useEffect(() => {
    // Scroll to the sectionw hen the component mounts
    if (sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);
 
  return (
    <section ref={sectionRef}>
    <div className="bg-[#345fe0dd] h-16"></div>
      <div className="relative text-[#262e45bb]">
        <div className="flex items-center p-4 sm:p-10 w-[100%] flex-col-reverse sm:flex-row justify-around sm:sticky top-0 min-h-[250vh]  ">
          <div className="  sm:w-[40%] flex flex-col  justify-center gap-5">
            <h1 className=" text-3xl sm:text-4xl font-semibold text-center sm:text-left">
            Terms & Conditions
            </h1>
            <h3 className=" text-3xl sm:text-4xl font-semibold text-center sm:text-left">
            1. Introduction
            </h3>
            <p className="text-justify sm:text-left">
            Welcome to Kirabiz Technologies! These Terms & Conditions ("Terms") govern your use of our website and the services provided by Kirabiz Technologies. By accessing or using our website, you agree to comply with these Terms. If you do not agree with these Terms, please refrain from using our website and services.
            </p>
            
            <h3 className=" text-3xl sm:text-4xl font-semibold text-center sm:text-left">
            2. Services Offered
            </h3>
            <p className="text-justify sm:text-left">
            Kirabiz Technologies is an IT Consultancy firm that offers a range of services, including website development, mobile application development, digital marketing, and corporate training for IT college students.
            </p>

            <h3 className=" text-3xl sm:text-4xl font-semibold text-center sm:text-left">
            3. User Responsibilities
            </h3>
            <p className="text-justify sm:text-left">
            You agree to use our website and services in compliance with applicable laws and regulations.
You are responsible for the accuracy of the information you provide to us.
You must not engage in any malicious activities that may harm our website or services.
            </p>

            <h3 className=" text-3xl sm:text-4xl font-semibold text-center sm:text-left">
            4. Privacy Policy
            </h3>
            <p className="text-justify sm:text-left">
            Your use of our website and services is also governed by our Privacy Policy, which outlines how we collect, use, and protect your personal information. Please review our Privacy Policy to understand how we handle your data.            </p>

            <h3 className=" text-3xl sm:text-4xl font-semibold text-center sm:text-left">
            5. Intellectual Property
            </h3>
            <p className="text-justify sm:text-left">
            All content and materials provided on our website, including text, images, logos, and software, are the property of Kirabiz Technologies and are protected by intellectual property laws.
You may not reproduce, distribute, or use our content without prior written permission.            </p>

            <h3 className=" text-3xl sm:text-4xl font-semibold text-center sm:text-left">
            6. Third-Party Links
            </h3>
            <p className="text-justify sm:text-left">
            Our website may contain links to third-party websites or services. These links are provided for your convenience, but we do not endorse or control the content of these third-party websites. Kirabiz Technologies is not responsible for any issues or disputes arising from your use of third-party websites.            </p>

            <h3 className=" text-3xl sm:text-4xl font-semibold text-center sm:text-left">
            7. Disclaimers
            </h3>
            <p className="text-justify sm:text-left">
            We strive to provide accurate and up-to-date information on our website. However, we do not guarantee the accuracy or completeness of the content.
Kirabiz Technologies is not liable for any damages or losses resulting from the use of our website or services.            </p>

            <h3 className=" text-3xl sm:text-4xl font-semibold text-center sm:text-left">
            8. Changes to Terms
            </h3>
            <p className="text-justify sm:text-left">
            We reserve the right to update or modify these Terms at any time without prior notice. Your continued use of our website and services after any changes indicate your acceptance of the updated Terms.            </p>

            <h3 className=" text-3xl sm:text-4xl font-semibold text-center sm:text-left">
            9. Contact Information
            </h3>
            <p className="text-justify sm:text-left">
            If you have any questions or 
            concerns regarding these Terms & Conditions, please contact us at info@kirabiz.in.       
            </p>

            <h3 className=" text-3xl sm:text-4xl font-semibold text-center sm:text-left">
            10. Governing Law
            </h3>
            <p className="text-justify sm:text-left">
            These Terms & Conditions are governed by the laws of Indian Government, and any disputes shall be resolved in the courts of Indian Government.            </p>

           
            
           
          
          </div>
        </div>
      </div>
    </section>
  );
};

export default TermsOfService;
